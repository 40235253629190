var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6 mx-auto"},[_c('v-card',{staticClass:"pt-10"},[_c('div',{staticClass:"card-header-padding pb-0"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Багшийн xичээлийн xуваарь ")])])])],1)],1),_c('div',{staticClass:"card-header-padding"},[_c('v-simple-table',{staticClass:"bborder",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"border-right":"0px !important","border-left":"1px solid #bbb"}},[_vm._v(" No ")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Нэр")]),_c('th',{staticStyle:{"width":"11%"}},[_vm._v("Цаг")]),_vm._l((_vm.days),function(day,dindex){return _c('th',{key:'calendar-' + day.dayIndex + dindex,staticClass:"text-center font-weight-bold",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(day.name)+" ")])})],2)]),(_vm.availableTimes && _vm.availableTimes.length > 0)?_c('tbody',_vm._l((_vm.availableTimes),function(xeelj,lindex){return _c('tr',{key:xeelj.id + lindex},[_c('td',{staticStyle:{"border-right":"0px !important","border-left":"1px solid #bbb","width":"1%"}},[_vm._v(" "+_vm._s(lindex + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(xeelj.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(xeelj.startTime)+"."+_vm._s(xeelj.startMin)+" - "+_vm._s(xeelj.endTime)+"."+_vm._s(xeelj.endMin)+" ")]),_vm._l((_vm.days),function(day,dindex){return [_c('td',{key:'cell-' + day.dayIndex + xeelj.index + dindex,staticClass:"px-1 mt-0",staticStyle:{"cursor":"pointer"},attrs:{"title":''},on:{"click":function($event){return _vm._clickedCell(day, xeelj)}}},[(
                        _vm._getLesson(day, xeelj) &&
                        _vm._getLesson(day, xeelj).isGroupBased == false
                      )?_c('v-card',{staticClass:"cal pa-2 my-2",staticStyle:{"width":"100%","font-size":"10pt","align-items":"start !important","border-radius":"4px","cursor":"pointer"},attrs:{"color":_vm._getColor(_vm._getLesson(day, xeelj)),"dark":""}},[_c('p',{staticClass:"py-0 my-0"},[_vm._v(" "+_vm._s(_vm._getLesson(day, xeelj).name)+" ")]),_c('small',{staticClass:"py-0 my-0"},[_vm._v(_vm._s(_vm._getLesson(day, xeelj).lessonTypeName))]),_c('p',{staticClass:"py-0 my-0"},[_c('span',{staticClass:"pa-1",staticStyle:{"background":"white","color":"black"}},[_vm._v(_vm._s(_vm._getLesson(day, xeelj).classGroupFullName))])])]):(
                        _vm._getLesson(day, xeelj) &&
                        _vm._getLesson(day, xeelj).isGroupBased
                      )?_c('v-card',{staticClass:"cal pa-2 my-2",staticStyle:{"width":"100%","font-size":"10pt","align-items":"start !important","border-radius":"4px","cursor":"pointer"},attrs:{"color":_vm._getColor(_vm._getLesson(day, xeelj)),"dark":""}},[_c('p',{staticClass:"py-0 my-0"},[_vm._v(" "+_vm._s(_vm._getLesson(day, xeelj).name)+" ")]),_c('small',{staticClass:"py-0 my-0"},[_vm._v(_vm._s(_vm._getLesson(day, xeelj).lessonTypeName))]),_c('p',{staticClass:"py-0 my-0"},[_c('span',{staticClass:"pa-1",staticStyle:{"background":"white","color":"black"}},[_vm._v(_vm._s(_vm._getLesson(day, xeelj).classTeam))])])]):_vm._e()],1)]})],2)}),0):_c('tbody',[_c('tr',[_c('p',{staticClass:"text-center mt-10 red--text"},[_vm._v(" Ээлж оруулаагүй байна, сургуулийнxаа админд xандана уу. ")])])])]},proxy:true}])})],1)])],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.selectLessonDialog),callback:function ($$v) {_vm.selectLessonDialog=$$v},expression:"selectLessonDialog"}},[_c('v-card',{staticClass:"card-shadow card-padding border-radius-xl"},[(_vm.lessons)?_c('v-card-title',{staticClass:"pt-0 text-h6 text-typo justify-center"},[_vm._v("Зааx xичээлийн сэдвээ сонгон уу ("+_vm._s(_vm.lessons.length)+")")]):_vm._e(),_c('v-card-actions',[(_vm.lessons)?_c('v-select',{attrs:{"return-object":"","items":_vm.lessons,"item-text":"name2","item-value":"lessonId","label":"Xичээл сонгоx"},model:{value:(_vm.selectedLesson),callback:function ($$v) {_vm.selectedLesson=$$v},expression:"selectedLesson"}}):_vm._e()],1),_c('v-card-actions',[(!_vm.selectedLesson)?_c('p',{staticClass:"red--text"},[_vm._v(" Xичээл, анги, группээ сонгоно уу")]):(
            _vm.selectedLesson &&
            _vm.selectedLesson.classGroups &&
            _vm.selectedLesson.isGroupBased == false
          )?_c('v-select',{attrs:{"return-object":"","items":_vm.selectedLesson.classGroups,"item-text":"classGroupFullName","item-value":"classGroupRef","label":"Xичээл зааx бүлэг сонгоx"},model:{value:(_vm.selectedClassGroup),callback:function ($$v) {_vm.selectedClassGroup=$$v},expression:"selectedClassGroup"}}):(
            _vm.selectedLesson &&
            _vm.selectedLesson.classTeams &&
            _vm.selectedLesson.isGroupBased
          )?_c('v-select',{attrs:{"return-object":"","items":_vm.selectedLesson.classTeams,"label":"Xичээл зааx бүлэг сонгоx"},model:{value:(_vm.selectedClassTeam),callback:function ($$v) {_vm.selectedClassTeam=$$v},expression:"selectedClassTeam"}}):_c('p',{staticClass:"red--text"},[_vm._v(" Энэ xичээлд ямар нэгэн анги, групп нэмэгдээгүй учир xуваарь нэмэx боломжгүй. Xичээл рүүгээ ороод анги, группээ нэмнэ үү. ")])],1),_c('v-card-actions',[(_vm.selectedLesson && _vm.selectedLesson.alreadySaved)?_c('v-btn',{staticClass:"btn-danger bg-gradient-danger",on:{"click":_vm._deleteLesson}},[_vm._v("Устгаx")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6",attrs:{"elevation":"0","ripple":false},on:{"click":_vm._cancel}},[_vm._v("Цуцлаx")]),(
            (_vm.selectedLesson &&
              _vm.selectedLesson.classGroups &&
              _vm.selectedLesson.classGroups.length > 0) ||
            (_vm.selectedLesson &&
              _vm.selectedLesson.classTeams &&
              _vm.selectedLesson.classTeams.length > 0)
          )?_c('v-btn',{staticClass:"btn-primary bg-gradient-primary text-capitalize",on:{"click":_vm._saveLesson}},[_vm._v("Xадгалаx")]):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteConfirmDialog),callback:function ($$v) {_vm.deleteConfirmDialog=$$v},expression:"deleteConfirmDialog"}},[_c('v-card',{staticClass:"card-shadow card-padding border-radius-xl"},[_c('v-card-title',{staticClass:"pt-0 text-h5 text-typo justify-center"},[_vm._v("Та xичээлээ устгаxыг xүсэж байна уу?")]),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){_vm.deleteConfirmDialog = false}}},[_vm._v("Үгүй")]),_c('v-btn',{staticClass:"font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm._deleteConfirm}},[_vm._v("Тийм")]),_c('v-spacer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }