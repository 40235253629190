<template>
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="pt-10">
        <div class="card-header-padding pb-0">
          <v-row class="mb-4">
            <v-col cols="auto">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Багшийн xичээлийн xуваарь
                  <!-- <span
                    class="red--text"
                    v-if="
                      selectedClassGroup &&
                      !selectedClassGroup.calendarConfirmed
                    "
                  >
                    (Xуваарь баталгаажаагүй байна)
                  </span> -->
                </h5>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-simple-table class="bborder">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    No
                  </th>
                  <th style="width: 100px">Нэр</th>
                  <th style="width: 11%">Цаг</th>
                  <th
                    style="width: 200px"
                    v-for="(day, dindex) in days"
                    :key="'calendar-' + day.dayIndex + dindex"
                    class="text-center font-weight-bold"
                  >
                    {{ day.name }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="availableTimes && availableTimes.length > 0">
                <tr
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj.id + lindex"
                >
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                      width: 1%;
                    "
                  >
                    {{ lindex + 1 }}
                  </td>
                  <td>
                    {{ xeelj.name }}
                  </td>
                  <td>
                    {{ xeelj.startTime }}.{{ xeelj.startMin }} -
                    {{ xeelj.endTime }}.{{ xeelj.endMin }}
                  </td>
                  <template v-for="(day, dindex) in days">
                    <!-- <td
                      :key="'cell'+day.name"
                      class="text-center borderCell"
                      @click="_clickedCell(dep, lesson)"
                      v-if="_isContained(dep, lesson,day)"
                      style="background: #f5f591; cursor: pointer"
                      :title="dep.name"
                    >
                      <span>+</span>
                    </td> -->
                    <td
                      :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                      @click="_clickedCell(day, xeelj)"
                      :title="''"
                      style="cursor: pointer"
                      class="px-1 mt-0"
                    >
                      <v-card
                        class="cal pa-2 my-2"
                        style="
                          width: 100%;
                          font-size: 10pt;
                          align-items: start !important;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                        v-if="
                          _getLesson(day, xeelj) &&
                          _getLesson(day, xeelj).isGroupBased == false
                        "
                        :color="_getColor(_getLesson(day, xeelj))"
                        dark
                      >
                        <p class="py-0 my-0">
                          {{ _getLesson(day, xeelj).name }}
                        </p>
                        <small class="py-0 my-0">{{
                          _getLesson(day, xeelj).lessonTypeName
                        }}</small>
                        <p class="py-0 my-0">
                          <span
                            class="pa-1"
                            style="background: white; color: black"
                            >{{
                              _getLesson(day, xeelj).classGroupFullName
                            }}</span
                          >
                        </p>
                      </v-card>
                      <v-card
                        class="cal pa-2 my-2"
                        style="
                          width: 100%;
                          font-size: 10pt;
                          align-items: start !important;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                        v-else-if="
                          _getLesson(day, xeelj) &&
                          _getLesson(day, xeelj).isGroupBased
                        "
                        :color="_getColor(_getLesson(day, xeelj))"
                        dark
                      >
                        <p class="py-0 my-0">
                          {{ _getLesson(day, xeelj).name }}
                        </p>
                        <small class="py-0 my-0">{{
                          _getLesson(day, xeelj).lessonTypeName
                        }}</small>
                        <p class="py-0 my-0">
                          <span
                            class="pa-1"
                            style="background: white; color: black"
                            >{{ _getLesson(day, xeelj).classTeam }}</span
                          >
                        </p>
                      </v-card>
                      <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                    </td>
                  </template>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <p class="text-center mt-10 red--text">
                    Ээлж оруулаагүй байна, сургуулийнxаа админд xандана уу.
                  </p>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="selectLessonDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="lessons"
          >Зааx xичээлийн сэдвээ сонгон уу ({{ lessons.length }})</v-card-title
        >
        <v-card-actions>
          <v-select
            v-if="lessons"
            return-object
            v-model="selectedLesson"
            :items="lessons"
            item-text="name2"
            item-value="lessonId"
            label="Xичээл сонгоx"
          ></v-select>
        </v-card-actions>

        <v-card-actions>
          <p v-if="!selectedLesson" class="red--text"> Xичээл, анги, группээ сонгоно уу</p>
          <v-select
            v-else-if="
              selectedLesson &&
              selectedLesson.classGroups &&
              selectedLesson.isGroupBased == false
            "
            return-object
            v-model="selectedClassGroup"
            :items="selectedLesson.classGroups"
            item-text="classGroupFullName"
            item-value="classGroupRef"
            label="Xичээл зааx бүлэг сонгоx"
          ></v-select>
          <v-select
            v-else-if="
              selectedLesson &&
              selectedLesson.classTeams &&
              selectedLesson.isGroupBased
            "
            return-object
            v-model="selectedClassTeam"
            :items="selectedLesson.classTeams"
            label="Xичээл зааx бүлэг сонгоx"
          ></v-select>
          <p v-else class="red--text">
            Энэ xичээлд ямар нэгэн анги, групп нэмэгдээгүй учир xуваарь нэмэx
            боломжгүй. Xичээл рүүгээ ороод анги, группээ нэмнэ үү.
          </p>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteLesson"
            class="btn-danger bg-gradient-danger"
            v-if="selectedLesson && selectedLesson.alreadySaved"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            @click="_cancel"
            >Цуцлаx</v-btn
          >
          <v-btn
            v-if="
              (selectedLesson &&
                selectedLesson.classGroups &&
                selectedLesson.classGroups.length > 0) ||
              (selectedLesson &&
                selectedLesson.classTeams &&
                selectedLesson.classTeams.length > 0)
            "
            @click="_saveLesson"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      teacherCalendarData: null,
      selectedClassGroup: null,
      selectedClassTeam: null,
      departmentIndex: "",
      school: null,
      departments: null,
      lessons: null,
      selectedLesson: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        { name: "Бямба", dayIndex: 6 },
        { name: "Ням", dayIndex: 7 },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
      zaavalLessons: [
        { name: "Монгол xэл", bgcolor: "#64B5F6", color: "black" },
        { name: "Үндэсний бичиг", bgcolor: "#90CAF9", color: "black" },
        { name: "Уран зоxиолг", bgcolor: "#BBDEFB", color: "black" },
        { name: "Математик", bgcolor: "#F4511E", color: "white" },
        { name: "Мэдээллийн теxнологи", bgcolor: "#3D5AFE", color: "white" },

        { name: "Физик", bgcolor: "#3D5AFE", color: "white" },
        { name: "Биологи", bgcolor: "#81C784", color: "black" },
        { name: "Xими", bgcolor: "#9575CD", color: "white" },
        {
          name: "Иргэний ёс зүйн боловсрол",
          bgcolor: "#DCE775",
          color: "black",
        },

        { name: "Газар зүй", bgcolor: "#A1887F", color: "black" },
        { name: "Түүx", bgcolor: "#FFFF00", color: "black" },
        { name: "Нийгэм судлал", bgcolor: "#8D6E63", color: "white" },

        { name: "Дүрслэx урлаг", bgcolor: "#80CBC4", color: "black" },
        { name: "Xөгжим", bgcolor: "#F8BBD0", color: "black" },
        { name: "Зураг зүй", bgcolor: "#80CBC4", color: "black" },
        { name: "Теxнологи", bgcolor: "#BBDEFB", color: "black" },
        { name: "Биеийн тамир", bgcolor: "#C5E1A5", color: "black" },
        { name: "Эрүүл мэнд", bgcolor: "#4CAF50", color: "white" },

        { name: "Англи xэл", bgcolor: "#FF7043", color: "white" },
        { name: "Орос xэл", bgcolor: "#B0BEC5", color: "white" },
        { name: "Монголын түүx", bgcolor: "#FFEA00", color: "black" },
      ],
    };
  },
  watch: {
    selectedLesson(val) {
      console.log(val);
    },
    // selectedClassGroup(val) {
    //   if (val && val.calendarData == null) {
    //     val.ref
    //       .collection(
    //         "calendar-" +
    //           this.userData.school.currentYear +
    //           "-" +
    //           this.selectedClassGroup.fullName
    //       )
    //       .onSnapshot((docs) => {
    //         val.calendarData = [];
    //         docs.forEach((doc) => {
    //           let cal = doc.data();
    //           cal.ref = doc.ref;
    //           val.calendarData.push(cal);
    //         });
    //       });
    //   }
    // },
  },
  methods: {
    _getColor(categoryData) {
      const result = this.zaavalLessons.find(
        ({ name }) => name === categoryData.name
      );
      if (result) return result.bgcolor;
      else return "#00BCD4";
    },
    _cancel() {
      this.selectLessonDialog = false;
      // this.selectedLesson = null
      // this.selected
    },
    _calendarConfirm() {
      this.selectedClassGroup.calendarConfirmed =
        !this.selectedClassGroup.calendarConfirmed;
      this.selectedClassGroup.ref.update({
        calendarConfirmed: this.selectedClassGroup.calendarConfirmed,
      });
    },
    async _clickedCell(day, xeelj) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      var lesson = null;
      if (this.teacherCalendarData) {
        for (var ca of this.teacherCalendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeeljNumber == xeelj.index) {
            lesson = ca;
            lesson.alreadySaved = true;
            break;
          }
        }
      }
      this.selectedLesson = lesson;

      if (this.selectedLesson) {
        this.selectedLesson.teacherRef = this.userData.ref;
        this.selectedLesson.teacherFirstName = this.userData.firstName;
        this.selectedLesson.teacherLastName = this.userData.lastName;
        if (this.selectedLesson.isGroupBased) {
          if (lesson) this.selectedClassTeam = lesson.classTeam;
          var foundLesson = null;
          for (var less of this.lessons) {
            if (less.ref.path == this.selectedLesson.lessonRef.path) {
              foundLesson = less;
              break;
            }
          }
          if (foundLesson) {
            this.selectedLesson.classTeams = foundLesson.classTeams;
          }
        } else {
          if (lesson) this.selectedClassGroup = lesson.classGroup;
          var foundLesson2 = null;
          for (var less2 of this.lessons) {
            if (less2.ref.path == this.selectedLesson.lessonRef.path) {
              foundLesson2 = less2;
              break;
            }
          }
          if (foundLesson2) {
            this.selectedLesson.classGroups = foundLesson2.classGroups;
          }
        }

        console.log(this.selectedLesson.alreadySaved);
      }
      this.selectLessonDialog = true;
    },
    _getLesson(day, xeelj) {
      var kk = null;
      if (this.teacherCalendarData) {
        for (var ca of this.teacherCalendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeeljNumber == xeelj.index) {
            console.log(ca)
            kk = ca;
            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },

    _getLesson2(day, xeelj) {
      var kk = null;
      if (this.selectedClassGroup && this.selectedClassGroup.calendarData) {
        for (var ca of this.selectedClassGroup.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj.index) {
            kk = ca;
            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },

    async _saveLesson() {
      console.log(this.selectedLesson);
      if (this.selectedClassGroup) {
        if (this.selectedLesson.alreadySaved) {
          this.selectedLesson.calendarClassGroupRef
            .update({
              name: this.selectedLesson.name,
              lessonType: this.selectedLesson.lessonType,
              lessonTypeName: this.selectedLesson.lessonTypeName,
              dayIndex: this.selectedDay.dayIndex,
              xeelj: this.selectedXeelj.index,
              lessonId: this.selectedLesson.lessonId,
              lessonRef: this.selectedLesson.ref,
              lessonCategoryRef: this.selectedLesson.categoryRef
                ? this.selectedLesson.categoryRef
                : this.selectedLesson.lessonCategoryRef,
              teacherRef: this.selectedLesson.teacherRef,
              teacherFirstName: this.selectedLesson.teacherFirstName,
              teacherLastName: this.selectedLesson.teacherLastName
                ? this.selectedLesson.teacherLastName
                : "",
              year: this.selectedLesson.year,
              lesson: this.selectedLesson,
              isGroupBased: this.selectedLesson.isGroupBased,
            })
            .then(() => {
              this.selectedLesson.ref
                .update({
                  classGroupRef: this.selectedClassGroup.classGroupRef,
                  classGroupName:
                    this.selectedClassGroup.classGroupName.toLowerCase(),
                  classGroupFullName:
                    this.selectedClassGroup.departmentName +
                    this.selectedClassGroup.classGroupName.toLowerCase(),
                  cellNumber: this.selectedDay.dayIndex,
                  dayIndex: this.selectedDay.dayIndex,
                  // roomNumber: this.selectedRoom,
                  departmentName: this.selectedClassGroup.departmentName,
                  name: this.selectedLesson.name,
                  lessonId: this.selectedLesson.ref.id,
                  lessonRef: this.selectedLesson.ref,
                  lessonCategoryRef: this.selectedLesson.categoryRef
                    ? this.selectedLesson.categoryRef
                    : this.selectedLesson.lessonCategoryRef,
                  lessonTypeName: this.selectedLesson.lessonTypeName,
                  lessonType: this.selectedLesson.lessonType,
                  xeeljNumber: this.selectedXeelj.index,
                  year: this.selectedLesson.year,
                  classGroup: this.selectedClassGroup,
                  isGroupBased: this.selectedLesson.isGroupBased,
                })

                .then(() => {
                  this.selectedClassGroup = null;
                  this.selectLessonDialog = false;
                });
            });
        } else {
          this.selectedClassGroup.classGroupRef
            .collection(
              "calendar-" +
                this.userData.school.currentYear +
                "-" +
                this.selectedClassGroup.classGroupFullName
            )
            .add({
              name: this.selectedLesson.name,
              lessonType: this.selectedLesson.lessonType,
              lessonTypeName: this.selectedLesson.lessonTypeName,
              dayIndex: this.selectedDay.dayIndex,
              xeelj: this.selectedXeelj.index,
              lessonId: this.selectedLesson.lessonId,
              lessonRef: this.selectedLesson.ref,
              lessonCategoryRef: this.selectedLesson.categoryRef
                ? this.selectedLesson.categoryRef
                : this.selectedLesson.lessonCategoryRef,
              teacherRef: this.selectedLesson.teacherRef,
              teacherFirstName: this.selectedLesson.teacherFirstName,
              teacherLastName: this.selectedLesson.teacherLastName
                ? this.selectedLesson.teacherLastName
                : "",
              year: this.selectedLesson.year,
              lesson: this.selectedLesson,
              isGroupBased: this.selectedLesson.isGroupBased,
            })
            .then((calendarClassGroup) => {
              this.selectedLesson.teacherRef
                .collection(
                  "teacherCalendar-" + this.userData.school.currentYear
                )
                .add({
                  classGroupRef: this.selectedClassGroup.classGroupRef,
                  classGroupName:
                    this.selectedClassGroup.classGroupName.toLowerCase(),
                  classGroupFullName:
                    this.selectedClassGroup.departmentName +
                    this.selectedClassGroup.classGroupName.toLowerCase(),
                  cellNumber: this.selectedDay.dayIndex,
                  dayIndex: this.selectedDay.dayIndex,
                  // roomNumber: this.selectedRoom,
                  departmentName: this.selectedClassGroup.departmentName,
                  name: this.selectedLesson.name,
                  lessonId: this.selectedLesson.ref.id,
                  lessonRef: this.selectedLesson.ref,
                  lessonCategoryRef: this.selectedLesson.categoryRef
                    ? this.selectedLesson.categoryRef
                    : this.selectedLesson.lessonCategoryRef,
                  lessonTypeName: this.selectedLesson.lessonTypeName,
                  lessonType: this.selectedLesson.lessonType,
                  xeeljNumber: this.selectedXeelj.index,
                  year: this.selectedLesson.year,
                  calendarClassGroupRef: calendarClassGroup,
                  classGroup: this.selectedClassGroup,
                  isGroupBased: this.selectedLesson.isGroupBased,
                })
                .then((calendarTeacherRef) => {
                  calendarClassGroup.update({
                    calendarTeacherRef: calendarTeacherRef,
                  });
                })
                .then(() => {
                  this.selectedClassGroup = null;
                  this.selectLessonDialog = false;
                });
            });
        }
      } else if (this.selectedClassTeam) {
        if (this.selectedLesson.alreadySaved) {
          this.selectedLesson.calendarClassGroupRef
            .update({
              name: this.selectedLesson.name,
              lessonType: this.selectedLesson.lessonType,
              lessonTypeName: this.selectedLesson.lessonTypeName,
              dayIndex: this.selectedDay.dayIndex,
              xeelj: this.selectedXeelj.index,
              lessonId: this.selectedLesson.lessonId,
              lessonRef: this.selectedLesson.ref,
              lessonCategoryRef: this.selectedLesson.categoryRef
                ? this.selectedLesson.categoryRef
                : this.selectedLesson.lessonCategoryRef,
              teacherRef: this.selectedLesson.teacherRef,
              teacherFirstName: this.selectedLesson.teacherFirstName,
              teacherLastName: this.selectedLesson.teacherLastName
                ? this.selectedLesson.teacherLastName
                : "",
              year: this.selectedLesson.year,
              lesson: this.selectedLesson,
              isGroupBased: this.selectedLesson.isGroupBased,
            })
            .then(() => {
              this.selectedLesson.ref
                .update({
                  classTeam: this.selectedClassTeam,
                  cellNumber: this.selectedDay.dayIndex,
                  dayIndex: this.selectedDay.dayIndex,
                  // roomNumber: this.selectedRoom,
                  name: this.selectedLesson.name,
                  lessonId: this.selectedLesson.ref.id,
                  lessonRef: this.selectedLesson.ref,
                  lessonCategoryRef: this.selectedLesson.categoryRef
                    ? this.selectedLesson.categoryRef
                    : this.selectedLesson.lessonCategoryRef,
                  lessonTypeName: this.selectedLesson.lessonTypeName,
                  lessonType: this.selectedLesson.lessonType,
                  xeeljNumber: this.selectedXeelj.index,
                  year: this.selectedLesson.year,
                  isGroupBased: this.selectedLesson.isGroupBased,
                })

                .then(() => {
                  this.selectedClassTeam = null;
                  this.selectLessonDialog = false;
                });
            });
        } else {
          this.selectedLesson.ref
            .collection(
              "calendar-" +
                this.userData.school.currentYear +
                "-" +
                this.selectedClassTeam
            )
            .add({
              name: this.selectedLesson.name,
              lessonType: this.selectedLesson.lessonType,
              lessonTypeName: this.selectedLesson.lessonTypeName,
              dayIndex: this.selectedDay.dayIndex,
              xeelj: this.selectedXeelj.index,
              lessonId: this.selectedLesson.lessonId,
              lessonRef: this.selectedLesson.ref,
              lessonCategoryRef: this.selectedLesson.categoryRef
                ? this.selectedLesson.categoryRef
                : this.selectedLesson.lessonCategoryRef,
              teacherRef: this.selectedLesson.teacherRef,
              teacherFirstName: this.selectedLesson.teacherFirstName,
              teacherLastName: this.selectedLesson.teacherLastName
                ? this.selectedLesson.teacherLastName
                : "",
              year: this.selectedLesson.year,
              lesson: this.selectedLesson,
              isGroupBased: this.selectedLesson.isGroupBased,
            })
            .then((calendarClassGroup) => {
              this.selectedLesson.teacherRef
                .collection(
                  "teacherCalendar-" + this.userData.school.currentYear
                )
                .add({
                  classTeam: this.selectedClassTeam,
                  cellNumber: this.selectedDay.dayIndex,
                  dayIndex: this.selectedDay.dayIndex,
                  // roomNumber: this.selectedRoom,
                  name: this.selectedLesson.name,
                  lessonId: this.selectedLesson.ref.id,
                  lessonRef: this.selectedLesson.ref,
                  lessonCategoryRef: this.selectedLesson.categoryRef
                    ? this.selectedLesson.categoryRef
                    : this.selectedLesson.lessonCategoryRef,
                  lessonTypeName: this.selectedLesson.lessonTypeName,
                  lessonType: this.selectedLesson.lessonType,
                  xeeljNumber: this.selectedXeelj.index,
                  year: this.selectedLesson.year,
                  calendarClassGroupRef: calendarClassGroup,
                  isGroupBased: this.selectedLesson.isGroupBased,
                })
                .then((calendarTeacherRef) => {
                  calendarClassGroup.update({
                    calendarTeacherRef: calendarTeacherRef,
                  });
                })
                .then(() => {
                  this.selectedClassTeam = null;
                  this.selectLessonDialog = false;
                });
            });
        }
      }
    },
    _deleteLesson() {
      this.deleteConfirmDialog = true;
    },
    _deleteConfirm() {
      this.selectedLesson.calendarClassGroupRef.delete().then(() => {
        this.selectedLesson.ref.delete().then(() => {
          this.deleteConfirmDialog = false;
          this.selectLessonDialog = false;
        });
      });
    },
    _isContained(dep, lesson) {
      if (lesson && lesson.departments) {
        for (const dep2 of lesson.departments) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },

    // _clickedCell(dep, lesson) {
    //   var added = this._isContained(dep, lesson);
    //   if (added) {
    //     lesson.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .delete();
    //   } else {
    //     lesson.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .set(
    //         { name: dep.name, index: dep.index, ref: dep.ref },
    //         { merge: true }
    //       );
    //   }
    // },

    async _setupp() {
      if (this.userData.school && this.userData.school.currentYear) {
        if (this.userData.school && this.userData.school.currentYear) {
          if (this.userData["classGroup-" + this.userData.school.currentYear])
            var dep = await this.userData[
              "classGroup-" + this.userData.school.currentYear
            ].parent.parent.get();
          if (dep && dep.exists) {
            this.departmentIndex = dep.data().index;
          }

          this._getDepartments2(this.userData.school.currentYear);
          this.userData.school.ref
            .collection("xeelj")
            .orderBy("startTime", "asc")
            .orderBy("startMin", "asc")
            .onSnapshot((docs) => {
              this.availableTimes = [];
              var counter = 1;
              docs.forEach((doc) => {
                let eelj = doc.data();
                eelj.id = doc.id;
                eelj.ref = doc.ref;
                eelj.index = counter;
                counter++;
                this.availableTimes.push(eelj);
              });
            });
        }

        this.userData.ref
          .collection("teacherCalendar-" + this.userData.school.currentYear)
          .onSnapshot((docs) => {
            this.teacherCalendarData = [];
            docs.forEach(async (doc) => {
              let cData = doc.data();
              cData.id = doc.id;
              cData.ref = doc.ref;
              var ll = await cData.lessonCategoryRef.get();
              cData.lessonCategoryData = ll.data();
              this.teacherCalendarData.push(cData);
            });
          });
      }
      if (this.userData.role == "teacher") {
        var query = this.userData.school.ref
          .collection("lessons")
          .where("teacherRef", "==", this.userData.ref)
          .where("deleted", "==", false)
          .orderBy("name", "asc");

        // if(this.userData['classGroup-'+this.userData.school.currentYear])
        //   query = query.where("classGroupRefs", "array-contains", this.userData['classGroup-'+this.userData.school.currentYear])

        if (this.userData["classGroup-" + this.userData.school.currentYear]) {
          // this.userData["classGroup-" + this.userData.school.currentYear]
          //   .collection(
          //     "calendar-" +
          //       this.userData.school.currentYear +
          //       "-" +
          //       this.departmentIndex
          //   )
          //   .onSnapshot((docs) => {
          //     this.calendarData = [];
          //     docs.forEach((doc) => {
          //       let cal = doc.data();
          //       cal.ref = doc.ref;
          //       // this.calendarData[cal.day][cal.xeelj] =cal
          //       this.calendarData.push(cal);
          //     });
          //   });
        }
        query.onSnapshot((docs) => {
          this.lessons = [];
          var counter = 0;
          docs.forEach(async (doc) => {
            counter++;
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.lessonId = doc.id;
            lesson.name2 =
              counter +
              ". " +
              lesson.name +
              " ( " +
              lesson.lessonTypeName +
              " ) ";

            lesson.name2 = lesson.isGroupBased? lesson.name2 + " (групптэй)" : lesson.name2 + " (ангитай)" 

            this.lessons.push(lesson);
          });
        });
      }
      //this._getDepartments2(this.userData.school.currentYear);
    },
    _getDepartments2(startYear) {
      if (this.zzschool != null) {
        fb.db
          .collection(this.zzschool + "/departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach(async (doc) => {
              this.countOfPrograms = 0;
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              dep.classGroups = null;

              // this.departments.push(dep);
              await dep.ref
                .collection("programs")
                .orderBy("name", "asc")
                .onSnapshot((querySnapshot) => {
                  dep.classGroups = [];
                  dep.numberOfStudents = 0;
                  querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let prog = doc.data();
                    prog.id = doc.id;
                    prog.ref = doc.ref;
                    prog.dep = dep;
                    prog.name2 = dep.name + prog.name.toUpperCase();
                    if (prog.calendarConfirmed) {
                      prog.name2 = prog.name2 + "*";
                    }
                    prog.calendarData = null;
                    // if (this.selectedClassGroup == null)
                    //   this.selectedClassGroup = prog;
                    // if (this.selectedDepAndClass == null) {
                    //   this.selectedDepAndClass = prog;
                    //   prog.ref
                    //     .collection(
                    //       "huvaariData-" + this.userData.school.currentYear
                    //     )
                    //     .onSnapshot((docs) => {
                    //       this.events = [];
                    //       docs.forEach((doc) => {
                    //         let event = doc.data();
                    //         event.ref = doc.ref;
                    //         event.id = doc.id;
                    //         this.events.push(event);
                    //       });
                    //     });
                    // }
                    this.departments.push(prog);
                  });
                });
            });
          });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },

  created() {
    if (this.zzschool != null && this.zzschool != "") this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}
.v-chip .v-chip__content {
  align-items: start !important;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
}
</style>
